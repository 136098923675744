<template>
  <div>
    <NewHomePage v-if="isShowNewHome"></NewHomePage>
    <OldHomePage v-else></OldHomePage>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import useSettingStore from '@/store/setting'
import useRequestLocationStore from '@/store/requestLocation'
import { VN_COUNTRY_CODE } from '@/utils'
import OldHomePage from './home/Home.vue'
import NewHomePage from './new-home/Home.vue'

const settingStore = useSettingStore()
const requestLocation = useRequestLocationStore()

const isShowNewHome = computed(
  () =>
    !!(
      requestLocation.countryCode &&
      requestLocation.countryCode.toUpperCase() != VN_COUNTRY_CODE &&
      settingStore.pages?.general?.homePageVersion?.toUpperCase() == 'V2'
    )
)
</script>
